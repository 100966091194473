import { render, staticRenderFns } from "./paymentWebhooksModal.vue?vue&type=template&id=6bdeb0a0&scoped=true"
import script from "./paymentWebhooksModal.vue?vue&type=script&lang=js"
export * from "./paymentWebhooksModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdeb0a0",
  null
  
)

export default component.exports