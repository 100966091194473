<template>
  <div>
    <div class="payment-error-container">
      <div class="d-block error-json-object small">
        <p class="d-block small" style="white-space: pre; overflow-wrap:break-word;">
          {{ JSON.stringify(this.error, null, 4) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paymentErrorModal',
  props: ['paymentId', 'error'],
  data() {
    return {}
  },
  mounted () {},
  methods: {},
}
</script>

<style scoped>

</style>
