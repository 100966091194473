<template>
  <div>
    <div class="small text-right">
<!--      <a-tooltip title="Открыть чат в новом окне" placement="bottom">-->
        <a :href="chatUrl" target="_blank"><a-icon type="block" /> {{ $t('labels.openInNewWindow') }}</a>
<!--      </a-tooltip>-->
    </div>
    <div class="height-80p d-block" style="height: 70vh">
      <iframe
        :src="chatUrl"
        width="100%"
        height="100%"
        frameborder="0" style="background: #fff;" >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentChatModal',
  props: ['chatObj'],
  data() {
    return {
      chatUrl: this.chatObj.agent_url,
    }
  },
}
</script>

<style scoped>

</style>
