<template>
  <div>
    <div class="payment-timeline">
      <a-timeline v-if="historyData && !loading" :reverse="true">
        <a-timeline-item v-for="data in historyData" :key="data.id" :color="getStatusColor(data.status)">
          {{ data.status }}
          <div class="small text-gray-5">
            {{ $moment.parseZone(data.created_at).format('DD.MM.YY') }}
            <span class="small">{{ $moment.parseZone(data.created_at).format('HH:mm:ss') }}</span>
          </div>
        </a-timeline-item>
      </a-timeline>
      <div v-else class="text-center"><a-spin /></div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api/api.service'

function getStatusColor(status) {
  const statusL = status.toLowerCase()
  switch (statusL) {
    case 'empty':
      return 'gray'
    case 'created':
      return 'gray'
    case 'accepted':
      return 'yellow'
    case 'cashed':
      return 'orange'
    case 'disputed':
      return 'orange'
    case 'rejected':
      return 'yellow'
    case 'declined':
      return 'red'
    case 'canceled':
      return 'red'
    case 'expired':
      return 'red'
    case 'confirmed':
      return 'green'
    case 'activated':
      return 'green'
    case 'completed':
      return 'green'
  }
}
export default {
  name: 'paymentStatusHistoryModal',
  props: ['paymentId'],
  data() {
    return {
      historyData: [],
      loading: true,
      getStatusColor,
    }
  },
  mounted () {
    this.getHistory()
  },
  methods: {
    async getHistory() {
      this.loading = true
      return ApiService.getClientPaymentStatusHistory(this.paymentId).then((response) => {
        this.historyData = response
      }).catch(error => {
        console.log(error)
        this.$message.error('Error while loading payment status history')
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
