<template>
    <a-modal v-model="isOpened"
             :destroyOnClose="true"
             :title="id ? `Attempts of webhook ${id}` : 'Attempts'"
             :footer="null"
             class="webhook-attempts-modal"
             width="720px"
             height="480px"
    >
        <a-table
            :columns="columns"
            :data-source="attempts"
            :row-key="record => record.id"
            :bordered="false"
            :pagination="false"
            size="small"
            class="ant-small-table"
        >
            <template v-slot:status_code="status_code">
                <span v-if="status_code === 200"><a-icon type="check" class="text-success"/></span>
                <span v-else><a-icon type="close" class="text-danger"/></span>
                {{ status_code }}
            </template>
            <template v-slot:created_at="created_at">
                {{ $moment.parseZone(created_at).format('DD.MM.YY') }}
                <span class="small text-gray-5"><a-icon
                    type="clock-circle"/> {{ $moment.parseZone(created_at).format('HH:mm:ss') }}</span>
            </template>
            <template v-slot:is_success="is_success">
                <a-alert class="py-0" :message="String(is_success)" :type="is_success ? 'success' : 'error'" />
            </template>
        </a-table>
    </a-modal>
</template>
<script>
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '15%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '35%',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'Status code',
    dataIndex: 'status_code',
    key: 'status_code',
    width: '25%',
    scopedSlots: { customRender: 'status_code' },
  },
  {
    title: 'Success',
    dataIndex: 'is_success',
    key: 'is_success',
    width: '25%',
    scopedSlots: { customRender: 'is_success' },
  },
]
export default {
  name: 'WebhooksAttemptsModal',
  data() {
    const isOpened = false
    const attempts = []
    const id = -1

    return {
      id,
      columns,
      isOpened,
      attempts,
    }
  },
}
</script>

<style lang="scss">
.webhook-attempts-modal {
    .ant-alert {
        width: fit-content;
    }
}
</style>
